import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>Not Found - Pamela Mary Brown</title>
    </Helmet>
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    <p>Check back soon for the magical reveal!!</p>
  </Layout>
)

export default NotFoundPage
